
import { Vue, Component, Ref } from "vue-property-decorator";
@Component({
  name: "Login",
})
export default class Login extends Vue {
  @Ref("form") formRef: any;

  private form = {
    username: "",
    password: "",
  };

  private rules = {
    username: [{ required: true, message: "请输入账号", trigger: "change" }],
    password: [{ required: true, message: "请输入密码", trigger: "change" }],
  };

  mounted() {
    console.log("route:", this.$route);
  }

  onSubmit() {
    this.formRef.validate((valid: boolean) => {
      if (valid) {
        const { username } = this.form;
        if (username === "admin") {
          this.$message.success("admin");
          this.$Cookies.set("vue_admin_token", "admin", { expires: 1 });
          this.handleTo();
        } else if (username === "visitor") {
          this.$message.success("visitor");
          this.$Cookies.set("vue_admin_token", "visitor", { expires: 1 });
          this.handleTo();
        } else {
          this.$message.warning("请检查账号密码！");
        }
      } else {
        return false;
      }
    });
  }

  handleTo() {
    const redirect = this?.$route?.query?.redirect as string;
    if (redirect) this.$router.push(redirect);
    else this.$router.push("/");
  }
}
